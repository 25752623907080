.login-container {
  margin: 0px;
  height: 100vh;
  background: url('../../assets/images/background.png') no-repeat center/cover;

  & h1 {
    text-align: center;
  }

  .wraper {
    padding-top: 400px;
  }

  .login-form-wraper {
    width: 420px;
    height: 320px;
    margin: 0 auto;
    padding-top:14px;
    background:hsla(0,0%,100%,.058823529411764705);
    border-radius: 5px;
    border: 1px solid #fff;
  }

  .login-form {
    padding: 0 12px;
  }

  .login-form-button {
    width: 100%;
  }
}



