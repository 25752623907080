.header {
  display: flex;
  align-items: flex-start;
  background-color: #fff !important;
  padding-left: 0;
  padding-right: 18px;

  & .user-center {
    margin-left: auto;
  }

  .logo img {
    vertical-align:middle
  }
}

.content {
  min-height: 500px;
}
